import eventModule from '../../../services/event';
import FiltersMixin from '../../../mixins/filtersMixins';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
const _ = require('lodash');

export default {
  name: 'rfmEventFilter',
  props: {
    selectedOnActionEvent: Object | String,
    readOnly: Boolean,
    channelType: String
  },
  components: {
    BeatLoader
  },
  mixins: [FiltersMixin],
  data() {
    return {
      fetchingData: true,

      eventList: null,
      selectedEvent: {
        eventName: null,
        properties: [],
        filters: []
      },
      currentFocusEvent: {
        eventName: null,
        properties: [],
        filters: []
      },
      eventMetadata: null,

      grwEventPropList: [],

      usemonetaryEvent: false,
      monetaryEventList: null,
      grwmonetaryEventPropList: [],
      selectedmonetaryEvent: {
        eventName: null,
        attribute: null,
        properties: []
      }
    };
  },

  methods: {
    isValidMonetaryProperty(dataType) {
      // Allow only numeric values
      let numbTypes = ['decimal', 'bigint', 'number', 'integer', 'float', 'double', 'long'];
      return numbTypes.indexOf(dataType) >= 0;
    },

    //#region ----------------------------- Query Builder methods --------------------

    // Called from parent.
    validate() {
      if (!this.selectedEvent.eventName) {
        this.warningToast('Please select RFM event first.');
        return false;
      }

      // If use monetary event.
      if (this.usemonetaryEvent) {
        if (!this.selectedmonetaryEvent.eventName) {
          this.warningToast('Please select Monetary event.');
          return false;
        } else if (!this.selectedmonetaryEvent.attribute) {
          this.warningToast('Please select Monetary Event Attribute.');
          return false;
        }
      }
      return true;
    },

    getFilters() {
      let result = {};

      // Parepare Main Event
      result.event = {
        eventName: this.selectedEvent.eventName,
        filters: []
      };
      for (let i = 0; i < this.selectedEvent.filters.length; i++) {
        let temp = this.selectedEvent.filters[i];

        if (temp.propertyColumn && temp.value) {
          result.event.filters.push({
            propertyName: temp.propertyName,
            propertyColumn: temp.propertyColumn,
            comparisonType: temp.comparisonType,
            value: temp.value
          });
        }
      }

      // Prepare monetary event.
      if (this.usemonetaryEvent) {
        result.monetary = {
          eventName: this.selectedmonetaryEvent.eventName,
          attribute: this.selectedmonetaryEvent.attribute
        };
      }

      return result;
    },

    convertFilterToRawFilter() {
      // set into this.selectedEvent
    },

    // Fetch event list from server
    fetchEventList() {
      this.fetchingData = true;

      eventModule
        .getEventList(this)
        .then((result) => {
          let listFromServer = Object.keys(result.data.data.eventList);

          // Build Custom Events
          let customEventsList = [];
          for (var i = 0; i < listFromServer.length; i++) {
            if (this.grwDefaultEventList.indexOf(listFromServer[i]) == -1) {
              customEventsList.push(listFromServer[i]);
            }
          }

          // Buil White-listed events
          let defaultEventsList = [];
          let channelDefaultEvents = this.grwChartTypeWiseDefaultEvents['rfm'];
          if (channelDefaultEvents) {
            for (let i = 0; i < listFromServer.length; i++) {
              if (channelDefaultEvents.indexOf(listFromServer[i]) != -1) {
                defaultEventsList.push(listFromServer[i]);
              }
            }
          }

          this.eventList = [];
          this.eventList.push(
            {
              label: 'Growlytics Events',
              options: defaultEventsList
            },
            {
              label: 'Custom Events',
              options: customEventsList
            }
          );

          // Remove all events which are hard coded growlytics events.
          this.fetchingData = false;
          this.eventMetadata = result.data.data.eventList;
          this.grwEventPropList = result.data.data.sessionPropList;
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    },

    getDisplayText(comparisonType, comparisionTypeList) {
      let data = _.find(comparisionTypeList, (c) => {
        return c.key === comparisonType;
      });
      return data.label;
    },

    onEventSelect() {
      let eventPropertyList = [
        {
          label: 'Growlytics Event Properties',
          options: this.grwEventPropList
        }
      ];
      eventPropertyList.push({
        label: 'Event Properties',
        options: this.eventMetadata[this.selectedEvent.eventName]
      });

      // White-list following default events.

      if (this.grwDefaultEventList.indexOf(this.selectedEvent.eventName) !== -1) {
        eventPropertyList.splice(0, 1);
      }

      this.selectedEvent.properties = eventPropertyList;
      this.selectedEvent.filters = [];
    },

    onMonetaryEventSelect() {
      let eventPropertyList = [
        {
          label: 'Growlytics Event Properties',
          options: this.grwEventPropList
        }
      ];
      eventPropertyList.push({
        label: 'Event Properties',
        options: this.eventMetadata[this.selectedmonetaryEvent.eventName]
      });

      // // White-list following default events.
      // if (this.grwDefaultEventList.indexOf(this.selectedEvent.eventName) !== -1) {
      //   eventPropertyList.splice(0, 1);
      // }

      this.selectedmonetaryEvent.properties = this.eventMetadata[this.selectedmonetaryEvent.eventName];
    },

    // set eventname on input focus
    setCurrentEventData(eventName, filter) {
      this.currentFocusEvent.eventName = eventName;
      this.currentFocusEvent.property = filter.propertyInfo.name;
    },

    // ON +where button click
    addPropertyFilter() {
      this.selectedEvent.filters.push({
        propertyName: null,
        propertyColumn: null,
        propertyInfo: null,
        comparisionTypeList: null,
        comparisonType: null,
        value: null
      });
    },

    // ON property dropdown change
    onFilterPropertyChange(subFilter, propertyGroupList) {
      let propertyList = [];
      for (let i = 0; i < propertyGroupList.length; i++) {
        propertyList = propertyList.concat(propertyGroupList[i].options);
      }

      // Read proeprty object from property name
      subFilter.propertyInfo = _.find(propertyList, (o) => {
        return o.columnName == subFilter.propertyColumn;
      });

      subFilter.propertyName = subFilter.propertyInfo.name;

      // Read property's datatype
      let propertyType = subFilter.propertyInfo.type;
      console.log('data type', propertyType);
      subFilter.comparisionTypeList = this.getComparisonsForDatatype(propertyType);
      subFilter.comparisonType = subFilter.comparisionTypeList[2].key;
    },

    // delete property filter from selectedEvent.filters
    deletePropertyFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    addPropertyGroupBy(eventFilter) {
      eventFilter.groupByList.push({
        propertyName: null
      });
    },

    // delete property filter from selectedEvent.filters
    deletePropertyGroupBy(groupByList, index) {
      groupByList.splice(index, 1);
    },

    //#endregion ----------------------------- Query Builder methods --------------------

    // Fetch event property suggestions
    fetchEventPropertySuggestion(queryString, cb) {
      let params = {
        eventName: this.currentFocusEvent.eventName,
        property: this.currentFocusEvent.property,
        queryString: queryString
      };
      eventModule
        .fetchCustomEventValueSuggetionsList(params)
        .then((result) => {
          if (result.data.success) {
            var links = result.data.data;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
          }
        })
        .catch((e) => {
          cb();
          this.reportError(e);
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    clearFilters() {
      this.selectedEvent = {
        eventName: null,
        properties: [],
        filters: []
      };
    }
  },
  mounted() {
    this.fetchEventList();
  }
};
